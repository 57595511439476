import React from "react";
import { FaMapMarkedAlt, FaPhoneAlt, FaRegClock, FaStar } from "react-icons/fa";
export const FAQs = [
    { question: "Can members gift their holiday?", answer: " Member can gift their holiday." },
    { question: "Can members advance their holiday?", answer: " Member can advance their holiday." },
    { question: "Can members save their holiday?", answer: "Yes, Member can save their holiday." },
    { question: "Can members split their holiday?", answer: " Member can split their holiday." },
    { question: "Is membership transferable one time?", answer: " Membership is one time transferable." },
    { question: "Will membership nights be carried forward if not utilized?", answer: "Yes, you can save your holidays." },
    { question: "What are the membership tiers?", answer: "We offer Silver, Gold, and Platinum tiers with increasing benefits and exclusive perks." },
    { question: "In case of non-utilization, will nights be carried forward to the next year?", answer: "Yes, In-case of non-utilization, nights would be carry forwarded to the next year.." },
    { question: "Can I upgrade my membership?", answer: "Yes, you can upgrade your membership at any time to access more benefits." },

];


export const Plans = [
    {
        name: "Basic",
        duration: [{ years: "5 Years", price: " ₹150000" }, { years: "10 Years", price: "₹260000" }, { years: "15 Years", price: "₹350000" }, { years: "25 Years", price: "₹400000" }],
        propertyType: "3 ⭐ to 4 ⭐",
        capacity: "2 adults and 2 kids (under 5 years)",
        annualMaintenance: "₹9999/year",
    },
    {
        name: "Premium",
        duration: [{ years: "5 Years", price: "₹250000" }, { years: "10 Years", price: "₹340000" }, { years: "15 Years", price: "₹400000" }, { years: "25 Years", price: "₹510000" }],
        propertyType: "4 ⭐ to 5 ⭐",
        capacity: "2 adults and 2 kids (under 5 years)",
        annualMaintenance: "₹9999/year",
    },
    {
        name: "Elite",
        duration: [{ years: "10 Years", price: "₹300000" }, { years: "10 Years", price: "₹530000" }, { years: "15 Years", price: "₹700000" }, { years: "25 Years", price: "₹850000" }],
        propertyType: "4 ⭐ to 5 ⭐",
        capacity: "4 adults and 2 kids (under 5 years)",
        annualMaintenance: "₹9999/year",
    },
    {
        name: "Corporate",
        duration: [{ years: "10 Years", price: "₹1200000" }, { years: "10 Years", price: "₹2200000" }, { years: "15 Years", price: "₹3000000" }, { years: "25 Years", price: "₹3500000" }],
        propertyType: "4 ⭐ to 5 ⭐",
        capacity: "Up to 50 adults",
        annualMaintenance: "₹9999/year",
    },
];

export const steps = [
    {
        title: "Choose a Plan",
        description: "Select from our range of flexible membership plans that suit your needs.",
        icon: "https://via.placeholder.com/100", // You can replace this with real icons or images.
    },
    {
        title: "Add Your Personal Details",
        description: "Provide your personal details to complete your membership profile.",
        icon: "https://via.placeholder.com/100",
    },
    {
        title: "Get Help from Our Helpdesk",
        description: "Our support team is always available to assist you with any queries.",
        icon: "https://via.placeholder.com/100",
    },
    {
        title: "Select Best Offers & Packages",
        description: "Choose from a variety of travel packages and exclusive deals for members.",
        icon: "https://via.placeholder.com/100",
    },
    {
        title: "Complete Payment",
        description: "Complete your membership payment through our secure payment gateway.",
        icon: <FaStar className="text-yellow-500 text-4xl" />,
    },
    {
        title: "Enjoy Ultimate Experience",
        description: "Enjoy your stay at our luxurious resorts and taste the best cuisine.",
        icon: "https://via.placeholder.com/100",
    },
];

export const benefits = [
    {
        title: "Flexible Point-Based Booking",
        description: "Enjoy a flexible, point-based booking system that allows you to plan vacations as per your needs.",
        icon: <FaRegClock className="text-blue-500 text-4xl" />,
    },
    {
        title: "Easy Upgrades & Top-ups",
        description: "Upgrade your membership or top-up points at any time to get access to more destinations.",
        icon: <FaStar className="text-yellow-500 text-4xl" />,
    },
    {
        title: "150+ Resorts Across India",
        description: "Access over 100+ luxurious resorts spread across scenic locations in India.",
        icon: <FaMapMarkedAlt className="text-green-500 text-4xl" />,
    },
    {
        title: "24/7 Dedicated Helpdesk",
        description: "Our helpdesk is available 24x7 to assist you with bookings, upgrades, and more.",
        icon: <FaPhoneAlt className="text-red-500 text-4xl" />,
    },
];
export const giftCards = [
    {
      id: 1,
      image: 'https://i.pinimg.com/736x/0e/3e/b7/0e3eb7ae4eca1f4fcd37b10778333810.jpg',
      title: 'Beach Getaway',
      description: 'Relax and unwind at exotic beaches.',
    },
    {
      id: 2,
      image: 'https://i.pinimg.com/736x/23/c0/88/23c0883fa391b126e144748aad46b888.jpg',
      title: 'Mountain Retreat',
      description: 'Escape to serene mountain retreats.',
    },
    {
      id: 3,
      image: 'https://i.pinimg.com/736x/83/1a/04/831a04619490a1066dea3b39e0b65633.jpg',
      title: 'City Adventures',
      description: 'Discover urban landscapes and adventures.',
    },
    {
      id: 4,
      image: 'https://i.pinimg.com/736x/b4/86/15/b4861553bfcd3952c5e7ea374522f9be.jpg',
      title: 'Luxury Resorts',
      description: 'Enjoy luxury and elegance at our resorts.',
    },
    {
      id: 5,
      image: 'https://i.pinimg.com/736x/65/74/95/65749557d7b679c71223cf15cca7c32c.jpg',
      title: 'Family Adventures',
      description: 'Create unforgettable memories with family.',
    },
    {
      id: 6,
      image: 'https://i.pinimg.com/736x/b7/02/b1/b702b1f7cdad1232264f7bd15fcc7d4b.jpg',
      title: 'Weekend Getaways',
      description: 'Short escapes for rejuvenating weekends.',
    },
    {
      id: 7,
      image: 'https://i.pinimg.com/736x/84/20/04/84200405d4ce26385fe13f55f38a16fe.jpg',
      title: 'Graduation Treats',
      description: 'Celebrate achievements with travel gifts.',
    },
    {
      id: 8,
      image: 'https://i.pinimg.com/736x/ca/8a/ff/ca8aff57082b3a1d49f462acd434c270.jpg',
      title: 'Corporate Retreats',
      description: 'Reward your team with unforgettable retreats.',
    },
  ];
 
  export const newsAndEvents = [
    {
      id: 1,
      type: 'news',
      title: 'Discover New Beach Resorts in 2024',
      subTitle: 'New Beach Destinations Open for Tourists',
      date: '25 Dec 2024',
      time: '10:00 AM',
      category: 'Beach Travel',
      logo: 'https://i.pinimg.com/736x/4a/1f/75/4a1f75e222a59cdfa906c6bac692aa9a.jpg',
      description: 'Discover exciting new beach destinations perfect for your next vacation.'
      
    },
    {
      id: 2,
      type: 'event',
      title: 'Mountain Hiking Event - Jan 2024',
      subTitle: 'Mountain Retreats Now Offering Discounts',
      date: '15 Jan 2024',
      time: '9:00 AM',
      category: 'Adventure',
      logo: 'https://i.pinimg.com/736x/35/64/74/356474f9e08ee9382d3ac7edb9162376.jpg',
      description: 'Unwind at serene mountain retreats with great discounts this season.'
    },
    {
      id: 3,
      type: 'news',
      title: 'Luxury Cruise Packages Announced',
      subTitle: 'Luxury Cruise Packages Announced',
      date: '10 Dec 2024',
      time: '11:30 AM',
      category: 'Cruise',
      logo: 'https://i.pinimg.com/736x/a6/2e/32/a62e32f87b82eddaaefa4ba6050a6ec5.jpg',
      description: 'Join us for an unforgettable evening of music in the mountains.'
    },
    {
      id: 4,
      type: 'event',
      title: 'Desert Safari: Exclusive Night Camp',
      subTitle: 'New Beach Destinations Open for Tourists',
      date: '20 Jan 2024',
      time: '5:00 PM',
      category: 'Desert',
      logo: 'https://i.pinimg.com/736x/a3/9f/13/a39f1399be4a73d23bb183fe68ca1030.jpg',
      description: 'Join us for an unforgettable evening of music in the mountains.'
    },
    {
      id: 5,
      type: 'news',
      title: 'Top 10 Travel Destinations for 2024',
      subTitle: 'New Beach Destinations Open for Tourists',
      date: '5 Jan 2024',
      time: '3:00 PM',
      category: 'Travel Tips',
      logo: 'https://i.pinimg.com/736x/02/b7/2d/02b72d07cc78e61dc75722f0b2367b86.jpg',
      description: 'Join us for an unforgettable evening of music in the mountains.'
    },
    {
      id: 6,
      type: 'event',
      title: 'Cultural Festival in Europe',
      subTitle: 'New Beach Destinations Open for Tourists',
      date: '30 Dec 2024',
      time: '7:00 PM',
      category: 'Culture',
      logo: 'https://i.pinimg.com/736x/8d/9c/1a/8d9c1a2bf4ad87655791770870b37f29.jpg',
      description: 'Ring in the New Year with a spectacular beach celebration.'
    },
  ];