// // src/OTPAuthentication.js
/* global recaptchaVerifier */

import { PhoneAuthCredential, PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import React, { useState, useRef ,useEffect, } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {loginSuccess} from '../../redux/auth/actions';
// import { auth } from './firebase';
// import { PhoneAuthProvider, RecaptchaVerifier, signInWithCredential, signInWithPhoneNumber } from 'firebase/auth';
// import { auth } from '../../auth/firebase';
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from '../../auth/firebase'; // named imports


const countryCodes = [
  { code: '+1', name: 'USA' },
  { code: '+91', name: 'India' },
  { code: '+44', name: 'UK' },
  // Add more country codes as needed
];

const OTPAuthentication = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState(countryCodes[0].code); // Default to the first country code
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const recaptchaRef = useRef(null); // Create a ref for reCAPTCHA container
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const otpInputs = useRef([]);
  useEffect(() => {
    // Initialize reCAPTCHA when the component mounts
    if (recaptchaRef.current) {
     window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                  'size': 'normal',
                  'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // ...
                  },
                  'expired-callback': () => {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                  }
                });
      window.recaptchaVerifier.render(); // Render reCAPTCHA widget
    }
  }, []);
  // const setUpRecaptcha = () => {
  //   // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
  //   //   size: 'normal', // or 'invisible' based on your preference
  //   //   callback: (response) => {
  //   //     // reCAPTCHA solved - will proceed with submit function
  //   //   },
  //   //   'expired-callback': () => {
  //   //     // Response expired. Ask user to solve reCAPTCHA again.
  //   //   }
  //   // }, auth);
  //   window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {}, auth);

  // };
  // useEffect(() => {
  //   if (!window.recaptchaVerifier) {
  //     window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
  //       size: "invisible", // Change to "normal" to see the reCAPTCHA box
  //       callback: (response) => {
  //         // reCAPTCHA solved, will proceed with send OTP
  //       },
  //       errorCallback: (error) => {
  //         console.error("Recaptcha error: ", error);
  //       },
  //     }, auth);
  //   }
  // }, []);
  
  const handleSendOtp = async (e) => {
     e.preventDefault();
    // // setUpRecaptcha();

    // const appVerifier = window.recaptchaVerifier;
    // try {
    //   // const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    //   //   size: 'normal', // or 'normal' to show the widget
    //   // }, auth);

    //   // window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    //   //             'size': 'normal',
    //   //             'callback': (response) => {
    //   //               // reCAPTCHA solved, allow signInWithPhoneNumber.
    //   //               // ...
    //   //             },
    //   //             'expired-callback': () => {
    //   //               // Response expired. Ask user to solve reCAPTCHA again.
    //   //               // ...
    //   //             }
    //   //           });

    //   // const confirmationResult = await signInWithPhoneNumber(auth, `${countryCode}${phoneNumber}`, appVerifier);
    //   const confirmationResult = await signInWithPhoneNumber(auth, `${countryCode}${phoneNumber}`, recaptchaVerifier);
    //             console.log("country code ::::",countryCode)
    //   setVerificationId(confirmationResult,verificationId);
    //   setIsOtpSent(true);
    //   alert('OTP sent to your phone!');
    // } catch (err) {
    //   console.log("country code ::::",countryCode)
    //   setError(err.message);
    // }
    setError(null);
    const appVerifier = window.recaptchaVerifier;

    try {
      window.confirmationResult = await signInWithPhoneNumber(auth, `${countryCode}${phoneNumber}`, appVerifier);
      console.log("OTP sent");
      setIsOtpSent(true);
    } catch (err) {
      console.error("Error sending OTP:", err);
      setError(err.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    // try {
    //   await confirmationResult.confirm(otp);
    //   setShowSuccessPopup(true);
    // } catch (err) {
    //   setError(err.message);
    // }
    // try {
    //   console.log('User signed in successfully:', verificationId, otp);
    //   const credential = PhoneAuthProvider.credential(verificationId, otp);
    //   // await signInWithCredential(auth, credential);
    //   console.log('User signed in successfully:', credential);
    //   const userCredential = await signInWithCredential(credential);
    //   console.log('User signed in successfully:', userCredential);
    //   alert("Login successful!");
    // } catch (error) {
    //   console.error("Error verifying OTP: ", error);
    // }
    setError(null);
    const confirmationResult = window.confirmationResult;

    if (!confirmationResult) {
      setError("Confirmation result is not available. Please request OTP again.");
      return;
    }

    try {
      const result = await confirmationResult.confirm(otp);
      console.log("User verified successfully", result.user.accessToken);
      dispatch(loginSuccess({ token: result.user.accessToken }));
      setShowSuccessPopup(true);
    } catch (err) {
      console.error("Error verifying OTP:", err);
      setError(err.message);
    }

  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;

    if (value.match(/^[0-9]{1}$/)) {
      const newOtp = otp.split('');
      newOtp[index] = value;
      setOtp(newOtp.join(''));

      if (index < otpInputs.current.length - 1) {
        otpInputs.current[index + 1].focus();
      }
    } else if (value === '') {
      if (index > 0) {
        otpInputs.current[index - 1].focus();
      }
    }
  };
  const handleClose=()=>{
    setShowSuccessPopup(false);
    navigate("/")
  }
  const handleOnClick=()=>{
    navigate("/")

}

  return (
    
    <div className="flex items-center justify-center min-h-screen bg-[#6C5F5B]">
            <div className="w-full max-w-md p-8 space-y-6 bg-[#4F4A45] rounded-lg shadow-md">
            <h1 className='bg-gradient-to-r from-blue-300 to-orange-300 text-transparent bg-clip-text text-3xl  font-bold font-serif'>PRAVASA</h1>
      <div ref={recaptchaRef} id="recaptcha-container"></div>

      {!isOtpSent ? (
        <form onSubmit={handleSendOtp} className="flex flex-col items-center">
          <div className="flex mb-4">
            <select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="border border-gray-300 rounded-l-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              {countryCodes.map(({ code, name }) => (
                <option key={code} value={code}>
                  {name} {code}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Enter mobile number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              className="border border-gray-300 rounded-r-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
          >
            Get OTP
          </button>
          <button
                type='submit'
                onClick={handleOnClick}
                className="w-full py-2 mt-4 text-blue-600 bg-transparent hover:bg-blue-500 focus:outline-none"
                >
                        Explore as guest!!
                </button>
        </form>
      ) : (
        <form onSubmit={handleVerifyOtp} className="flex flex-col items-center">
          <div className="flex mb-4 space-x-2">
            {[...Array(6)].map((_, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                ref={(el) => (otpInputs.current[index] = el)}
                onChange={(e) => handleOtpChange(e, index)}
                className="border border-gray-300 rounded-md p-2 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-12 h-12 text-center"
              />
            ))}
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
          >
            Verify OTP
          </button>
        </form>
      )}

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {showSuccessPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-6 rounded-md shadow-md">
            <h3 className="text-lg font-bold">Success!</h3>
            <p>Your phone number has been verified successfully.</p>
            <button
              onClick={() => handleClose()}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default OTPAuthentication;
// import React, { useState } from 'react';
// import { auth, RecaptchaVerifier, signInWithPhoneNumber } from '../../auth/firebase';
// import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth';

// const OTPAuthentication = () => {
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [otp, setOtp] = useState('');
//   const [showOtpInput, setShowOtpInput] = useState(false);
//   const [verificationId, setVerificationId] = useState(null);

//    // Initialize reCAPTCHA only once
//   //  const initializeRecaptcha = () => {
//   //   if (!window.recaptchaVerifier) {
//   //     window.recaptchaVerifier = new RecaptchaVerifier(
//   //       'recaptcha-container',
//   //       {
//   //         size: 'invisible', // Or 'normal' for visible reCAPTCHA
//   //         callback: (response) => {
//   //           console.log("Recaptcha solved: ", response);
//   //         },
//   //         'expired-callback': () => {
//   //           console.log('Recaptcha expired');
//   //         }
//   //       },
//   //       auth
//   //     );
//   //     window.recaptchaVerifier.render().then((widgetId) => {
//   //       window.recaptchaWidgetId = widgetId; // Capture widget ID for future use
//   //     });
//   //   }
//   // };

//   const sendOtp = async (e) => {
//     e.preventDefault();
//     try {
//       if (!window.recaptchaVerifier) {
//         window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
//           'size': 'normal',
//           'callback': (response) => {
//             // reCAPTCHA solved, allow signInWithPhoneNumber.
//             // ...
//           },
//           'expired-callback': () => {
//             // Response expired. Ask user to solve reCAPTCHA again.
//             // ...
//           }
//         });
//       }
//         const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
//       setVerificationId(confirmationResult.verificationId);
//       setShowOtpInput(true);
//       console.log("otp sent")
//     } catch (error) {
//       console.error('Error sending OTP:', error);
//     }
//   };

//   const verifyOtp = async (e) => {
//     e.preventDefault();
//     try {
//       const credential = PhoneAuthProvider.credential(verificationId, otp);
//       const userCredential = await signInWithCredential(auth, credential);
//       console.log('User signed in successfully:', userCredential);
//       alert('Logged in successfully');
//     } catch (error) {
//       console.error('Error verifying OTP:', error);
//     }
//   };

//   return (
//     <div>
//       <form onSubmit={sendOtp}>
//         <h3>OTP Login</h3>
//         <div id="recaptcha-container"></div>
//         <input
//           type="tel"
//           value={phoneNumber}
//           onChange={(e) => setPhoneNumber(e.target.value)}
//           placeholder="Enter phone number"
//           required
//         />
//         <button 
//         type="submit"
//         className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
//         >Send OTP</button>
//       </form>

//       {showOtpInput && (
//         <form onSubmit={verifyOtp}>
//           <input
//             type="text"
//             value={otp}
//             onChange={(e) => setOtp(e.target.value)}
//             placeholder="Enter OTP"
//             required
//           />
//           <button type="submit">Verify OTP</button>
//         </form>
//       )}
//     </div>
//   );
// };

// export default OTPAuthentication;
