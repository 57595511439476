import React, { useState } from "react";
import { motion } from "framer-motion"; // For animations
import Footer from "../../common/Footer";
import Nav from "../../common/Nav";

const donationPosts = [
  {
    id: 1,
    title: "Help Poor Children to Study",
    image: "https://via.placeholder.com/300",
    description:
      "Your donation will help provide educational resources, books, and school supplies to underprivileged children.",
    raised: 3500,
    goal: 5000,
  },
  {
    id: 2,
    title: "Food Distribution for the Needy",
    image: "https://via.placeholder.com/300",
    description:
      "Support our food distribution drive to provide meals to families in need.",
    raised: 2500,
    goal: 4000,
  },
  {
    id: 3,
    title: "Blanket Distribution for Winter",
    image: "https://via.placeholder.com/300",
    description:
      "Help us distribute blankets to those in need during the harsh winter season.",
    raised: 1800,
    goal: 3000,
  },
];

const NGOServicePage = () => {
  const [selectedPost, setSelectedPost] = useState(null);

  const handleDonateClick = (post) => {
    setSelectedPost(post);
  };

  const closeModal = () => {
    setSelectedPost(null);
  };

  return (
    <div>
        <div className="p-6">
            <Nav/>
        </div>
    <div className="bg-gray-50 min-h-screen py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-900 text-center mb-12">
          <span className="text-orange-400">PravasaOne</span> - Make a Difference
        </h1>

        {/* Donation Posts */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {donationPosts.map((post, index) => (
            <motion.div
              key={post.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <img
                src={post.image}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-lg font-bold text-gray-800">{post.title}</h2>
                <p className="text-gray-600 mt-2">{post.description}</p>
                <div className="mt-4">
                  {/* Progress Bar */}
                  <div className="w-full bg-gray-200 rounded-full h-4">
                    <div
                      className="bg-blue-600 h-4 rounded-full"
                      style={{
                        width: `${(post.raised / post.goal) * 100}%`,
                      }}
                    ></div>
                  </div>
                  <p className="text-sm text-gray-500 mt-2">
                    Raised: ${post.raised} / ${post.goal}
                  </p>
                </div>
                <button
                  onClick={() => handleDonateClick(post)}
                  className="mt-6 w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-orange-400 transition"
                >
                  Give a Hand
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Modal for Payment Options */}
      {selectedPost && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6">
            <h2 className="text-xl font-bold text-gray-800">
              Support: {selectedPost.title}
            </h2>
            <p className="text-gray-600 mt-4">
              Your contribution will help us reach our goal of ${selectedPost.goal}.
            </p>
            <div className="mt-4">
              <label className="block text-gray-700 font-medium">
                Enter Donation Amount:
              </label>
              <input
                type="number"
                placeholder="Enter amount"
                className="w-full border border-gray-300 rounded-lg p-2 mt-2"
              />
            </div>
            <div className="mt-6">
              <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition">
                Donate Now
              </button>
              <button
                onClick={closeModal}
                className="w-full bg-gray-300 text-gray-800 py-2 rounded-lg mt-2 hover:bg-gray-400 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    <Footer/>
    </div>
  );
};

export default NGOServicePage;
