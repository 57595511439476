import React from 'react';
import { motion } from 'framer-motion';
import { giftCards } from '../common/data';
import Nav from './Nav';
import Footer from './Footer';

const GiftCardPage = () => {
  return (
    <div className=' bg-gray-100'>
    <div className='p-6'>
        <Nav />
    </div>
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-center text-blue-600 mb-8">
          Gift Cards for Every Occasion
        </h2>
        <p className="text-center text-gray-500 mb-12">
          Find the perfect travel gift card for any occasion.
        </p>

        {/* Responsive Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {giftCards.map((card, index) => (
            <motion.div
              key={card.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.5 }}
              className="relative overflow-hidden bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              {/* Card Image */}
              <img
                src={card.image}
                alt={card.title}
                className="w-full h-48 object-cover"
              />

              {/* Hover Text Overlay */}
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center text-white p-4">
                <div className="text-center">
                  <h3 className="text-xl font-bold">{card.title}</h3>
                  <p className="mt-2 text-sm">{card.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
    
      <Footer/>
    </div>
  );
};

export default GiftCardPage;
