// src/Footer.js
import React from 'react';
import Logo from '../assets/Logo.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    
    const dynamicLinks = [
        { name: 'Home', href: '/' },
        { name: 'Membership', href: '/membership' },
        { name: 'AboutUs', href: '/aboutUs' },
        { name: 'Blog', href: '/blog' },
    ];

    const socialLinks = [
        { name: 'Facebook', href: 'https://facebook.com', icon: 'https://img.icons8.com/ios-filled/50/facebook--v1.png' },
        { name: 'Twitter', href: 'https://twitter.com', icon: 'https://img.icons8.com/ios-filled/50/twitterx.png' },
        { name: 'Instagram', href: 'https://instagram.com', icon: 'https://img.icons8.com/ios-filled/50/instagram-new--v1.png' },
    ];
    const navigate = useNavigate
    const handleLogoClick=()=>{
        navigate('/');
    }
    return (
        <footer className="bg-gradient-to-r from-blue-300 to-orange-300  text-white   ">
            <div className="container px-4-0 md:p-6  mx-auto ">
                <div className="flex flex-col md:flex-row justify-between">
                    <div className="flex flex-col mb-4">
                    <div className="flex-1 flex justify-center   md:item-start md:justify-start">
                      <img src={Logo} className='w-48 h-48' ></img>
                      </div>
                        {/* <img src="/logo.png" alt="Logo" className="h-12 mb-2" /> */}
                        <p className=" text-center text-sm">Contact: info@pravasa.com</p>
                        <p className="text-center text-sm">Phone: +91-8209626480</p>
                    </div>
                    <div className="flex flex-col md:pt-20  text-center mb-4">
                        <h5 className="font-bold mb-4">Links</h5>
                        <ul>
                            {dynamicLinks.map((link) => (
                                <li key={link.name}>
                                    <a href={link.href} className="text-gray-100 mr-8  font-semibold font-sans no-underline hover:text-gray-500">
                                        {link.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-col md:pt-20 mb-4 text-center">
                        <h5 className="font-bold mb-4">Follow Us</h5>
                        <div className="flex justify-center md:justify-end space-x-4">
                            {socialLinks.map((link) => (
                                <a key={link.name} href={link.href} className="text-gray-300 hover:text-white">
                                    <img src={link.icon}></img>
                                    
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="border-t border-gray-700 mt-4 p-4 text-center text-sm">
                    &copy; {new Date().getFullYear()} PRAVASA. All rights reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;
