import React, { useState }  from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Link } from 'react-router-dom';
import '../common/nav.css';
import Logo from '../assets/Logo.png';
import { useSelector } from "react-redux";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  // Fetch `isLoggedIn` and `userImage` from Redux store
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userImage = useSelector((state) => state.auth.userImage);
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  return (
  <div>
    
    {/* <nav class="navbar navbar-expand-lg navbar-dark shadow-5-strong position-sticky">
      
      <div class="container-fluid  ">
        
       
        <Link class="nav-linknavbar-brand ms-5 txtHeading"  to="/">PRAVASA</Link>
    
        
        <button
          class="navbar-toggler"
          type="button"
          data-mdb-toggle="collapse"
          data-mdb-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars"></i>
        </button>
    
        
        <div class="collapse navbar-collapse " id="navbarSupportedContent">
         
          <ul class="navbar-nav me-5  mb-3 mb-lg-3 ms-auto ">
            <li class="nav-item">
            <Link class="nav-link" aria-current="page" to="/destination">Destination</Link>
              
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/hotel">Hotels</Link>
            </li>
            
            <li class="nav-item">
            <Link class="nav-link" to="/flight">Flights</Link>
            </li>
            
            <li class="nav-item">
            <Link class="nav-link" to="/Booking">Bookings</Link>
            </li>
            <li class="nav-item">
            <Link class="nav-link" to="/membership">Membership</Link>
            </li>
          </ul>
          
        </div>
        
      </div>
      
    </nav> */}
    
    <nav className=" fixed  w-full    h-20 relative ">
      <div className="backdrop-blur-md bg-opacity-30 shadow-lg absolute container-fluid  h-full mx-auto flex items-center justify-between md:justify-start">
        {/* Logo - centered on mobile, left-aligned on larger screens */}
        <div className="flex-1 flex justify-center  lg:ml-20 md:justify-start">
          <Link to="/" className="w-32 h-32"><img src={Logo} className='w-full h-full'></img></Link>
        </div>

        {/* Navigation Links - hidden on mobile by default */}
        <div className="hidden md:flex flex-row justify-center item-center   lg:mr-20 md:mr-0 space-x-4 ">
          {/* <a href="#" className="text-black text-2xl font-bold font-mono underline decoration-sky-500/30 hover:text-gray-400 mr-4 ">Hotel</a> */}
          <Link to="/hotel" className="text-black text-2xl font-semibold font-sans no-underline  lg:mr-4  md:mr-0 p-2">Discover</Link>

          <Link to="/membership" className="text-black text-2xl font-semibold font-sans no-underline hover:text-gray-400 md:mx-0 lg:mx-4 p-2">PravasaOne</Link>

          {/* <a href="#" className="text-black text-2xl font-bold font-mono underline decoration-sky-500/30 decoration-gray-600 hover:text-gray-400 mx-4">Flight</a> */}
          <Link to="/aboutUs" className="text-black text-2xl font-semibold font-sans no-underline decoration-gray-600 hover:text-gray-400 md:mx-0 lg:mx-4 p-2">AboutUs</Link>
          <Link to="/blog" className="text-black text-2xl font-semibold font-sans  no-underline decoration-gray-600 hover:text-gray-400 md:mx-0 md:pl-0 lg:mx-4 lg:pl-4 p-2">Blog</Link>
          <Link to="/NGO" className="text-black text-2xl font-semibold font-sans  no-underline decoration-gray-600 hover:text-gray-400 md:mx-0 md:pl-0 lg:mx-4 lg:pl-4 p-2">PravasaCare</Link>

          {isLoggedIn ? (
            <div className="relative flex flex-col justify-center">
              <button
                className="text-black text-2xl font-semibold font-sans no-underline hover:text-gray-200"
                onClick={toggleDropdown}
              >
                My Profile
              </button>

              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <div className="absolute top-12 right-0 left-0 mt-2 w-48 bg-gray-300 rounded-lg shadow-lg z-50">
                  <a
                    href="/my-bookings"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 no-underline"
                  >
                    My Bookings
                  </a>
                  <a
                    href="/my-membership"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 no-underline"
                  >
                    My Membership
                  </a>
                  <a
                    href="/update-profile"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 no-underline"
                  >
                    Update Profile
                  </a>
                  <a
                    href="/update-documents"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 no-underline"
                  >
                    Update Documents
                  </a>
                  <button
                    onClick={() => console.log('Logout logic here')}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 no-underline"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ):(
          <Link to="/login" className="bg-gradient-to-r from-blue-300 to-orange-300 hover:from-pink-500 hover:to-orange-500 no-underline rounded-md text-black text-2xl font-bold font-sans decoration-gray-600 hover:text-gray-400 md:ml-0  lg:ml-4 p-2">REGISTER</Link>
          )}
          {/* <a href="#" className="text-black text-2xl font-bold font-mono decoration-gray-600 hover:text-gray-400 ml-4"><img src=''></img></a> */}
        </div>
       {/* Search Button and Input */}
       {/* <div className="relative">
          <button
            onClick={toggleSearch}
            className="text-black focus:outline-none flex items-center mx-4"
          >
             <svg className="w-8 h-8 p-2 rounded-full bg-gray-600 shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105"
              fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 4a7 7 0 1 1 0 14 7 7 0 0 1 0-14zm7 7h.01M15.37 15.37l3.54 3.54"></path>
            </svg>
          </button>

          <input
            type="text"
            placeholder="Search..."
            className={`absolute right-0 top-full mt-2 px-4 py-2 border rounded-md bg-white shadow-md transition-all duration-300 ease-in-out ${isSearchOpen ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full'}`}
            style={{ width: isSearchOpen ? '200px' : '0px' }} // adjust width as needed
          />
        </div> */}

        {/* Mobile Menu Toggle Button */}
        <button onClick={toggleMenu}  className="md:hidden right-0 flex justify-end text-black focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
        </div>
        {/* Side Menu - visible on mobile */}
      <div 
        className={`fixed inset-0 bg-gray-800 bg-opacity-75 z-50 transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300`}
      >
        <div className="flex flex-col p-4">
          <button 
            onClick={toggleMenu} 
            className="text-white text-2xl mb-4 self-end"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          {/* <Link to="/" className="text-white text-2xl font-semibold font-sans no-underline mb-4">Home</Link>
          <Link to="/hotel" className="text-white text-2xl font-semibold font-sans no-underline mb-4">Discover</Link>
          <Link to="/membership" className="text-white text-2xl font-semibold font-sans no-underline mb-4">Membership</Link>
          <Link to="/aboutUs" className="text-white text-2xl font-semibold font-sans no-underline mb-4">AboutUs</Link> */}
          <div className="flex items-center px-4 py-2 cursor-pointer">
          <img
                  src={ userImage||"https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
          <Link to="/" className="text-white text-2xl font-semibold font-sans no-underline ">Home</Link>
          </div>
          <div className="flex items-center px-4 py-2 cursor-pointer">
          <img
                  src={ "https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
          <Link to="/hotel" className="text-white text-2xl font-semibold font-sans no-underline ">Discover</Link>
          </div>
          <div className="flex items-center px-4 py-2 cursor-pointer">
          <img
                  src={ "https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
          <Link to="/membership" className="text-white text-2xl font-semibold font-sans no-underline ">PravasaOne</Link>
          </div>
          <div className="flex items-center px-4 py-2 cursor-pointer">
          <img
                  src={ "https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
          <Link to="/aboutUs" className="text-white text-2xl font-semibold font-sans no-underline ">AboutUs</Link>
          </div>
          <div className="flex items-center px-4 py-2 cursor-pointer">
          <img
                  src={ "https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
                 <Link to="/blog" className="text-white text-2xl font-semibold font-sans no-underline ">Blog</Link>
          </div>
          <div className="flex items-center px-4 py-2 cursor-pointer">
          <img
                  src={ "https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
                 <Link to="/NGO" className="text-white text-2xl font-semibold font-sans no-underline ">PravasaCare</Link>
          </div>
          {/* <Link to="/blog" className="text-white text-2xl font-semibold font-sans no-underline mb-4">Blog</Link> */}
          {isLoggedIn ? (
            <div>
              <div
                className="flex items-center px-4 py-2 cursor-pointer"
                onClick={toggleDropdown}
              >
                <img
                  src={ "https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
                <span className="font-semibold text-white no-underline text-2xl">My Profile</span>
              </div>
              {isDropdownOpen && (
                <div className="border-t bg-gray-500 opacity-70 rounded-2xl">
                  <a
                    href="/my-bookings"
                    className="block px-4 py-2 text-white text-xl no-underline hover:bg-gray-100"
                  >
                    My Bookings
                  </a>
                  <a
                    href="/my-membership"
                    className="block px-4 py-2 text-white text-xl no-underline hover:bg-gray-100"
                  >
                    My Membership
                  </a>
                  <a
                    href="/update-profile"
                    className="block px-4 py-2 text-white text-xl no-underline hover:bg-gray-100"
                  >
                    Update Profile
                  </a>
                  <a
                    href="/update-documents"
                    className="block px-4 py-2 text-white text-xl no-underline hover:bg-gray-100"
                  >
                    Update Documents
                  </a>
                  <button
                    onClick={() => console.log("Logout logic here")}
                    className="block w-full text-left px-4 py-2 text-white text-xl  no-underline hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center px-4 py-2 cursor-pointer">
          <img
                  src={ "https://via.placeholder.com/40"}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-gray-300 mr-3"
                />
          <Link to="/login" className="text-white text-2xl mb-4 no-underline">register</Link>
          </div>
          )}

        </div>
      </div>
    </nav>
    
    </div>
  )
}

export default Nav