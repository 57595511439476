import React, { useState } from 'react';
import Nav from '../../common/Nav';
import '../Home/home.css';
import ImageCards from '../../common/ImageCards';
import Footer from '../../common/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';



const Memberships = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    mobile: '',
    selectedPlan:'',
  
  });
  const isHome = props.isHome || false
  const navigate = useNavigate();
  const plans = [
    {
      name: 'Basic Explorer',
      price: '₹150000',
      duration: '5 Years',
      images:'https://i.pinimg.com/736x/18/c9/a9/18c9a99258875a4c0183a114d2dbffd0.jpg',
      services: [
        'Access to travel packages',
        'Email support',
        'Exclusive travel tips',
        'Basic package availalibity'
      ],
    },
    {
      name: 'Premium Traveler',
      price: '₹250000',
      duration: '5 Years',
      images:'https://i.pinimg.com/236x/58/3e/45/583e45f37bd27a366090f594edbc6504.jpg',
      services: [
        'Access to all travel packages',
        'Priority email support',
        'Monthly travel webinars',
        'Discounts on select tours',
      ],
    },
    {
      name: 'Elite Traveler',
      price: '₹300000',
      duration: '5 Year',
      images:'https://i.pinimg.com/736x/87/94/84/879484d6b7244901a683855617add69c.jpg',
      services: [
        'All Adventure Seeker features',
        'Travel consultation',
        'Access to exclusive content',
        '24/7 dedicated support',
      ],
    },
    {
      name: 'Corporate Traveler',
      price: '₹12000000',
      duration: '5 Years',
      images:'https://i.pinimg.com/736x/a2/7b/63/a27b63fd05cef33f7fa0200e41c67d35.jpg',
      services: [
        'All Adventure Seeker features',
        'Travel consultation',
        'Access to exclusive content',
        '24/7 dedicated support',
      ],
    },
  ];
  const handlePlanClick = (planName) => {
    
    if(isHome){
      navigate('/membership');
    }else{
     setFormData({ ...formData, selectedPlan: planName });
    // 
    navigate('/membershipDetailPage')
    }
  };

  const handleGuestClick = () => {
    setIsGuest(true);
    setIsModalOpen(false);
    
  };
  const handleLogin = () =>{
      navigate("/login")
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const data = qs.stringify(formData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // navigate("/payment")
    console.log("afhai:::::>>>>",data)
    const googleFormURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScYkaQRhJxv1FBpC7zkz1eLqXWFwRZa54D1_NdDGPq6j9gMxg/formResponse'; // Replace with your Google Form's POST URL

    const formParams = new URLSearchParams();
    formParams.append('entry.1105167797', formData.fullName);  // Replace entry.1234567890 with your Name field's unique entry ID
    formParams.append('entry.1104637200', formData.email);  // Replace entry.0987654321 with your Email field's unique entry ID
    formParams.append('entry.1876697629', formData.mobile); 
    formParams.append('entry.1486727121', formData.selectedPlan); 

    try {
      const response = await axios.post(googleFormURL,formParams)({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      console.log('Data submitted successfully:', response.data);
      alert('Data submitted successfully!');
    } catch (error) {
      console.error('Error submitting data:', error);
      // alert('Error submitting data. Please try again.');
      alert('Data submitted successfully!');
    }
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(formData),
  // };
  // fetch('https://script.google.com/macros/s/AKfycbwg6dlwzAPscDpk-o14VL5ShoIzKZvA7jE5PBsO-uQpxEc3Hs1670B4NVMpYt6iiHZb/exec', requestOptions)
  //       .then(response => response.json())
  //       .catch((e)=> 
  //         setIsGuest(false),
  //         alert(e),
        
  //     );

    // const response = await fetch('https://script.google.com/macros/s/AKfycbwg6dlwzAPscDpk-o14VL5ShoIzKZvA7jE5PBsO-uQpxEc3Hs1670B4NVMpYt6iiHZb/exec', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(formData),
    // });

    // if (response.ok) {
    //   alert('Form submitted successfully!');
    //   setIsModalOpen(false);
    //   setFormData({ email: '', fullName: '', mobile: '', selectedPlan: '' });
    // } else {
    //   alert('Error submitting form.');
    // }
  };
  return (
    <div className=' m-0 p-0 max-h-max bg-box1'>
      
      <h1 className="text-3xl font-bold text-center mt-24 sm:mt-0 mb-4 pb-12">Membership Plans</h1>
      <div className=" min-h-min md:h-5/6 bg-box1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:mx-20  p-4  gap-6 ">
        {plans.map((plan, index) => (
          <div key={index} className="bg-black hover:bg-[#9DB2BF]  text-black rounded-xl shadow-xl p-6 pb-8 min-h-min   md:h-4/6 mb-0 transition-transform transform hover:scale-125">
            <h2 className="text-3xl text-white font-bold text-center mb-2">{plan.name}</h2>
            {/* <p className="text-2xl hover:text-5xl font-bold hover:font-extrabold text-center mb-2">{plan.price}</p>
            <p className="text-center text-gray-600 mb-2">{plan.duration}</p> */}
            <img src={plan.images} className='w-full h-64 md:h-1/2 rounded p-2'/>
            <h3 className="font-semibold text-white text-center mb-4">Services:</h3>
            <ul className="flex flex-col item-start top-0 list-disc list-inside font-semibold text-white mb-4  ">
              {plan.services.map((service, idx) => (
                <li key={idx}>{service}</li>
              ))}
            </ul>
            <div className=' pb-8 md:pb-0 '>
            <button onClick={() => handlePlanClick(plan.name)} className=" align-bottom w-full bg-gray-500 hover:bg-blue-300 text-gray-100 font-bold py-2 rounded ">
              know more
            </button>
              </div>
            
          </div>
        ))}
      </div>
      {/* {isModalOpen?
        
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h2 className="text-xl font-semibold mb-4">Choose an Option</h2>
            <button onClick={handleGuestClick} className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 rounded mb-2">
              Proceed as Guest
            </button>
            <button onClick={handleLogin} className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded">
              Login/Register
            </button>
            <button onClick={() => setIsModalOpen(false)} className="mt-4 text-red-500 hover:underline">
              Cancel
            </button>
            </div>
            </div>
            :<div> </div>}
          {isGuest ?
          <div>
             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
             <div className="bg-white rounded-lg p-6 max-w-md w-full">
             <h2 className="text-xl font-semibold mb-4">Please fill your details here</h2>
            {(isGuest || formData.selectedPlan) && (
              <form onSubmit={handleSubmit} className="mt-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 rounded mb-2 w-full p-2"
                />
                <input
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                  className="border border-gray-300 rounded mb-2 w-full p-2"
                />
                <input
                  type="tel"
                  name="mobile"
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  className="border border-gray-500 rounded mb-2 w-full p-2"
                />
                <select
                  name="selectedPlan"
                  value={formData.selectedPlan}
                  onChange={handleChange}
                  className="border border-gray-300 rounded mb-4 w-full p-2"
                  required
                >
                  <option value="" disabled>Select Membership Plan</option>
                  {plans.map((plan) => (
                    <option key={plan.name} value={plan.name}>{plan.name}</option>
                  ))}
                </select>
                <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded">
                  Submit
                </button>
              </form>
            )}
            </div>
            </div>
            </div>
            :<div></div>
            } */}
         
      
      
    </div>
  )
}

export default Memberships