import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/Footer';
import Nav from '../../common/Nav';

const BookingCheckoutPage = () => {
  const navigate = useNavigate();

  // Dummy Event Details (replace with real data or API integration)
  const eventDetails = {
    id: 'E12345',
    name: 'Mountain Music Festival 2024',
    description: 'Join us for an unforgettable evening of music in the mountains.',
  };

  // Form State
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    paymentMode: 'Credit Card', // Default payment mode
  });

  // Handle Input Changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Checkout
  const handleCheckout = (e) => {
    e.preventDefault();
    console.log('Checkout Data:', formData);
    navigate('/payments'); // Redirect to payments page
  };

  return (
    <div>
      <div className='p-6'>
        <Nav/>
      </div>
    <div className="bg-gray-50 py-12 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-6">
        {/* Header */}
        <h1 className="text-2xl font-bold text-gray-800 mb-6">Booking Checkout</h1>

        {/* Event Details */}
        <div className="bg-gray-100 rounded-lg p-4 mb-6">
          <h2 className="text-lg font-bold text-gray-700">{eventDetails.name}</h2>
          <p className="text-sm text-gray-500">Event ID: {eventDetails.id}</p>
          <p className="text-gray-600 mt-2">{eventDetails.description}</p>
        </div>

        {/* Form */}
        <form onSubmit={handleCheckout} className="space-y-6">
          {/* User Information */}
          <div>
            <h3 className="text-lg font-semibold text-gray-700 mb-4">User Information</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Address */}
          <div>
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Address</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
                type="text"
                name="address"
                placeholder="Street Address"
                value={formData.address}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                name="postalCode"
                placeholder="Postal Code"
                value={formData.postalCode}
                onChange={handleInputChange}
                required
                className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>

          {/* Payment Mode */}
          <div>
            <h3 className="text-lg font-semibold text-gray-700 mb-4">Payment Mode</h3>
            <select
              name="paymentMode"
              value={formData.paymentMode}
              onChange={handleInputChange}
              required
              className="border border-gray-300 rounded-lg p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="Credit Card">Credit Card</option>
              <option value="Debit Card">Debit Card</option>
              <option value="UPI">UPI</option>
              <option value="Net Banking">Net Banking</option>
              <option value="PayPal">PayPal</option>
            </select>
          </div>

          {/* Checkout Button */}
          <button
            type="submit"
            className="w-full bg-blue-600 text-white rounded-lg py-3 font-semibold hover:bg-blue-700 transition duration-300"
          >
            Proceed to Payment
          </button>
        </form>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default BookingCheckoutPage;
