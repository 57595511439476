// src/redux/auth/reducer.js
import { LOGIN_SUCCESS, LOGOUT } from "./actionTypes";

const initialState = {
  isLoggedIn: false,
  token: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.token, // Update token in state
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        token: null, // Reset token on logout
      };
    default:
      return state;
  }
};

export default authReducer;
