import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { motion } from 'framer-motion';
import Nav from '../../common/Nav';
import Footer from '../../common/Footer';
import { newsAndEvents } from '../../common/data';

// const newsAndEvents = [
//   // Sample data (same structure as before)
//   { id: 1, image: 'https://i.pinimg.com/736x/4a/1f/75/4a1f75e222a59cdfa906c6bac692aa9a.jpg', title: 'New Beach Destinations Open for Tourists', date: '2024-12-25', time: '10:00 AM', type: 'news', description: 'Discover exciting new beach destinations perfect for your next vacation.' },
//   { id: 2, image: 'https://i.pinimg.com/736x/35/64/74/356474f9e08ee9382d3ac7edb9162376.jpg', title: 'Mountain Retreats Now Offering Discounts', date: '2024-12-20', time: '4:00 PM', type: 'news', description: 'Unwind at serene mountain retreats with great discounts this season.' },
//   { id: 6, image: 'https://via.placeholder.com/600x400', title: 'New Year’s Eve Celebration at the Beach', date: '2024-12-31', time: '8:00 PM', type: 'event', description: 'Ring in the New Year with a spectacular beach celebration.' },
//   { id: 7, image: 'https://via.placeholder.com/600x400', title: 'Mountain Music Festival 2024', date: '2024-12-28', time: '6:00 PM', type: 'event', description: 'Join us for an unforgettable evening of music in the mountains.' },
//   // Add more data
// ];

const NewsEventDetailedPage = () => {
  const { id } = useParams(); // Get the card ID from the URL
  const navigate = useNavigate();
  const currentItem = newsAndEvents.find((item) => item.id === parseInt(id)); // Find the current news/event

  if (!currentItem) {
    return <p className="text-center text-red-500">Event or News not found.</p>;
  }

  const handleBooking = () => {
    navigate('/booking-checkout'); // Redirect to the booking page
  };

  return (
    <div>
        <div className='p-6'>
            <Nav/>
       
    </div>
    <div className="bg-gray-50 py-12 px-4">
      <div className="max-w-4xl mx-auto">
        {/* Image */}
        <LazyLoadImage
          src={currentItem.logo}
          alt={currentItem.subTitle}
          className="w-full h-96 object-fill rounded-lg shadow-lg mb-8"
          effect=""
        />

        {/* Title and Date */}
        <h1 className="text-3xl font-bold text-gray-800 mb-4">{currentItem.title}</h1>
        <p className="text-sm text-gray-500 mb-4">
          {currentItem.date} - {currentItem.time}
        </p>

        {/* Description */}
        <p className="text-gray-700 leading-relaxed mb-8">{currentItem.description}</p>

        {/* Booking Button */}
        <button
          onClick={handleBooking}
          className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
        >
          Book Now
        </button>
      </div>

      {/* Slider Section */}
      <div className="mt-16">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Explore Other News and Events</h2>
        <motion.div
          className="flex space-x-4 overflow-x-auto pb-4"
          whileTap={{ cursor: 'grabbing' }}
        >
          {newsAndEvents
            .filter((item) => item.id !== currentItem.id) // Exclude the current item
            .map((item) => (
              <motion.div
                key={item.id}
                className="min-w-[250px] bg-white rounded-lg shadow-lg p-4 cursor-pointer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate(`/news-and-events/${item.id}`)}
              >
                <LazyLoadImage
                  src={item.logo}
                  alt={item.title}
                  className="w-full h-40 object-cover rounded-md mb-4"
                  effect="blur"
                />
                <h3 className="text-lg font-bold text-gray-800">{item.title}</h3>
                <p className="text-sm text-gray-500">
                  {item.date} - {item.time}
                </p>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default NewsEventDetailedPage;
