// DestinationGallery.js
import React from 'react';
import { motion } from 'framer-motion';

const destinations = [
  { name: "Goa", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk0x5PNFmUun7m5z2uPMN7xUHyrpHhv1WxWA&s", description: "Explore beautiful beaches and nightlife." },
  { name: "Shimla", image: "https://mytriphack.com/wp-content/uploads/2016/03/bijapur_itinerary-cover.jpg", description: "Experience the serene mountains." },
  { name: "Manali", image: "https://www.traveltricky.com/wp-content/uploads/2021/07/Best-Places-To-Visit-In-South-India.png", description: "Adventure in the Himalayas." },
  { name: "Kerala", image: "https://www.oyorooms.com/blog/wp-content/uploads/2018/07/shutterstock_655466914.jpg", description: "Discover tranquil backwaters." },
  { name: "Jaipur", image: "https://images.herzindagi.info/her-zindagi-english/images/2024/09/26/article/image/no-network-1727361636371.jpg", description: "Dive into rich heritage and palaces." },
  { name: "Mumbai", image: "https://bookretreats.com/blog/wp-content/uploads/2024/01/Desert-retreat-India-3.jpg", description: "The city that never sleeps." },
];

const DestinationGallery = () => {
  return (
    <div className="p-12  bg-box1 h-full shadow-2xl">
      <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">Explore Destinations</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {destinations.map((destination, index) => (
          <motion.div
            key={index}
            className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer group"
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300, damping: 20 }}
          >
            <img
              src={destination.image}
              alt={destination.name}
              className="w-full h-48 object-cover rounded-lg transition-transform duration-300 ease-in-out group-hover:scale-110"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 p-4">
              <h2 className="text-lg font-semibold text-white mb-2">{destination.name}</h2>
              <p className="text-sm text-gray-200">{destination.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default DestinationGallery;
